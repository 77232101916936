import { Component, Inject } from '@angular/core';
import { getIsLoggedIn, getLoginUrl } from '@innogy/account';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import { EnvironmentConfig } from '@innogy/core-config-models';
import { getSiteContext } from '@innogy/core/analytics';
import {
  getBrandLogoLinkUrl,
  getLanguage,
  getMainNavigation,
  getServiceNavigationRootName,
  getShowNavigation,
  getSitecoreContextWithStatus,
} from '@innogy/jss-integration';
import { login, loginUrl, myEnvironmentActive } from '@innogy/core-settings';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ed-default-header-container',
  templateUrl: './ed-default-header-container.component.html',
})
export class EDDefaultHeaderContainerComponent {
  public language$ = this.store$.select(getLanguage);

  public readonly isLoggedIn$ = this.store$.select(getIsLoggedIn);

  private readonly mainNavigationInfo$ = this.store$.pipe(getMainNavigation);
  public isMainNavigationAvailable$ = this.mainNavigationInfo$.pipe(
    map((info) => info.isNavigationAvailable)
  );
  public mainNavigationItems$ = this.mainNavigationInfo$.pipe(
    map((info) => info.navItems)
  );

  private readonly myEnvironmentActive$ =
    this.store$.select(myEnvironmentActive);
  private readonly hasContext$ = this.store$.pipe(
    getSitecoreContextWithStatus,
    map((context) => context != null)
  );
  public readonly serviceNavigationActive$ = combineLatest([
    this.myEnvironmentActive$,
    this.hasContext$,
    this.isLoggedIn$,
  ]).pipe(
    map(
      ([active, hasContext, isLoggedIn]) => active && hasContext && isLoggedIn
    )
  );
  public serviceNavigationTitle$ = this.store$.pipe(
    getServiceNavigationRootName
  );
  public serviceNavigationLink$ = this.store$.pipe(select(loginUrl));

  public brandLogoLinkUrl$ = this.store$.select(getBrandLogoLinkUrl);

  public loginUrlLabel$ = this.store$.pipe(select(login));
  public loginUrl? = getLoginUrl(this.config);
  public placement$ = getSiteContext(this.store$, this.config);
  public showNavigation$ = this.store$.select(getShowNavigation);

  constructor(
    private readonly store$: Store<any>,
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig
  ) {}
}
